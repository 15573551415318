import './App.css';
import Auction from './components/Auction'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import FAQs from './components/FAQs';
import {Route, Routes} from 'react-router-dom'
import Bridge from './components/Bridge';


function App() {
  return (
    <>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/market" element={<Auction />} />
          <Route path="/bridge" element={<Bridge />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
